import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import MessagesApiService from "@/core/services/api.service.messages";
import { MESSAGE_CHANNEL_TYPE } from "@/view/pages/messages/messages-enums";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "messages-list",
  data() {
    return {
      tableData: [],
      filteredTableData: [],
      channelEnum: MESSAGE_CHANNEL_TYPE,
      members: {
        membersDialog: false
      },
      messageRecipients: null,
      showOnlyTemplates: false
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.getMessageList();
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.messages");
    },
    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",
          width: "3%",
          sortable: false
        },
        { text: "Program name", align: "left", value: "programName", width: "30%" },
        {
          text: "Scheduled Date",
          align: "center",
          value: "scheduledDate",
          width: "15%"
        },
        {
          text: "Sent Date",
          align: "center",
          value: "sendDate",
          width: "15%"
        },
                {
          text: "Template",
          align: "center",
          value: "template",
          width: "3%",
          sortable: false
        },
        {
          text: "Channel",
          align: "center",
          value: "channel",
          sortable: false,
          width: "3%",
          class: "channel-bg"
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
          width: "10%"
        },
        {
          text: "Receiver Type",
          align: "center",
          value: "receiverType",
          sortable: false,
          width: "7%"
        },
        { text: "Targets", align: "center", value: "target", sortable: false, width: "3%" },
        { text: "", align: "center", value: "actions", sortable: false, width: "1%" }
      ];
    },
  },
  methods: {
    filterTableData() {
      if (this.showOnlyTemplates) {
        return this.tableData.filter(item => item.template);
      } else {
        return this.tableData;
      }
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("DD-MM-yyyy HH:mm");
    },
    showMembersDialog(members) {
      members.membersDialog = true;
      this.getRecipients(members.id);
    },
    hideMembersDialog() {
      this.members.membersDialog = false;
    },

    async getRecipients(messageId) {
      this.messageRecipients = await MessagesApiService.getMessageData(messageId);
      //   this.messageRecipients = response;
      console.log(this.messageRecipients);
    },
    async getMessageList() {
      const response = await MessagesApiService.getMessageList();
      const messages = response?.data || [];

      this.tableData = messages.reverse();
      this.filteredTableData = this.filterTableData();
    },
  },
  watch: {
    showOnlyTemplates() {
      this.filteredTableData = this.filterTableData();
    }
  },

};
