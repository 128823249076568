import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**
 * Service to call HTTP request via Axios
 */
const MessagesApiService = {
  URL: "",
  API_BASE: process.env.VUE_APP_API_BASE,
  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },
  API_MESSAGES: "api/messages",
  API_MESSAGES_INFO: (messageId) => `/api/messages/${messageId}/recipients-info`,
  API_IMAGE_UPLOAD: "api/upload/image-upload-without-saving-to-db",
  API_IMAGE_UPLOAD_V2: "api/upload/media-upload",
  API_MESSAGE_GROUPS: "api/groups",
  API_SEND_MESSAGE: "api/messages/send",
  API_FILTER_RECIPIENTS: "api/messages/filter-recipients",
  API_RECIPIENT_INFO: "api/messages/recipients-info",
  /**
   * Send the GET HTTP request
   * @param params
   * @returns {*}
   */

  createMessage(data) {
    //   const URL = this.API_BASE;

    try {
      return Vue.axios.post(this.URL + this.API_SEND_MESSAGE, data);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },
  async getMessageData(messageId) {

    try {
      const response = await Vue.axios.get(this.URL + this.API_MESSAGES_INFO(messageId), {
      });
      return response.data
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },

  uploadImage(image) {
    // this.init();
    //const URLr = 'https://apicloud-test.ooblee.me/';
    try {
      const formData = new FormData();
      formData.append("image", image);
      return Vue.axios.post(this.URL + this.API_IMAGE_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },
  filterRecipients(filter) {
    const params = {
      ...filter
    };
    // const URL = 'https://admin-panel-cloud-test.ooblee.me/';
    try {
      return Vue.axios.post(this.URL + this.API_FILTER_RECIPIENTS, params);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },

  async getGroups() {
    /*    const config = {
      params
    };*/
    // const URL = this.API_BASE;
    try {
      return await Vue.axios.get(this.URL + this.API_MESSAGE_GROUPS);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },
  async getMessageList() {
    try {
      return await Vue.axios.get(this.URL + this.API_MESSAGES);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },

  /**
   *
   * @param {*} data
   * {
   *  "type": "GROUP",
   *     "ids": [
   *         3,
   *        9,
   *        18
   *    ]
   * }
   * @returns
   */
  async getRecipientInfo(data) {
    try {
      return await Vue.axios.post(this.URL + this.API_RECIPIENT_INFO, data);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  }
};

export default MessagesApiService;
