var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"topbar-item mb-6"},[_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('div',{staticClass:"flex-type flex-column p-5"},[_c('div',{staticClass:"d-flex mx-h align-center"},[_c('v-checkbox',{model:{value:(_vm.showOnlyTemplates),callback:function ($$v) {_vm.showOnlyTemplates=$$v},expression:"showOnlyTemplates"}}),_c('p',[_vm._v("Show only templates")])],1)]),_c('router-link',{attrs:{"to":{ name: 'messages-form' }}},[_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green darken-1"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" NEW COMMUNICATION ")],1)],1)],1)]),(!_vm.tableData || !_vm.tableData.length)?_c('p',{staticClass:"text-center",staticStyle:{"font-size":"18px","color":"rgba(154,153,153,0.89)"}},[_vm._v(" Message drafts aren't available ")]):_vm._e(),(_vm.filteredTableData && _vm.filteredTableData.length)?_c('v-data-table',{staticClass:"elevation-2 messages-table",attrs:{"headers":_vm.headers,"items":_vm.filteredTableData,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_c('div',[_vm._v(_vm._s(item.id))])]),_c('td',[_c('div',[_vm._v(_vm._s(item && item.programName ? item.programName : "N/A"))])]),_c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.scheduledDate ? _vm.parseDate(item.scheduledDate) : "N/A")+" ")])]),_c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.sendDate ? _vm.parseDate(item.sendDate) : "N/A")+" ")])]),_c('td',{staticClass:"text-center"},[(!_vm.showOnlyTemplates || item.template)?_c('div',[(item.template)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e()]),_c('td',{staticClass:"text-center channel-bg"},[_c('div',[_vm._v(_vm._s(item.channel ? item.channel : "N/A"))])]),_c('td',{class:{
            'status-bg': item.status === 'SUCCESSFUL',
            'schedule-bg': item.status === 'SCHEDULED',
            'fail-bg': item.status === 'FAILED'
          }},[_c('div',[_vm._v(_vm._s(item.status ? item.status : "N/A"))])]),_c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.receiverType ? item.receiverType : "N/A")+" ")])]),_c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.receiverIds && item.receiverIds.length ? item.receiverIds.length : "N/A")+" ")])]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('router-link',{attrs:{"to":{
                  name: 'messages-form',
                  params: { messageData: item }
                }}},[_c('v-list-item',[_vm._v(" Resend Message ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px","click-outside-to-close":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){return _vm.showMembersDialog(item)}}},on),[_vm._v(" View Message Recipients ")])]}}],null,true),model:{value:(_vm.members.membersDialog),callback:function ($$v) {_vm.$set(_vm.members, "membersDialog", $$v)},expression:"members.membersDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400px"}},[_c('v-card-title',{staticClass:"text-center"},[_c('span',{staticClass:"headline"},[_vm._v("Message Recipients ")])]),_c('v-card-text',[_c('div',{staticClass:"members"},_vm._l((_vm.messageRecipients),function(participant,index){return _c('div',{key:index,staticClass:"member"},[(!participant.code)?_c('div',[_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("ID:")]),_vm._v(" "+_vm._s(participant.id))]),_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Username:")]),_vm._v(" "+_vm._s(participant.nickName)+" ")]),_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Email:")]),_vm._v(" "+_vm._s(participant.email)+" ")])]):_c('div',[_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Group code:")]),_vm._v(" "+_vm._s(participant.code)+" ")]),_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Group name:")]),_vm._v(" "+_vm._s(participant.owner.username)+" ")]),_vm._l((participant.otherParticipants),function(participant){return _c('div',{key:participant.id,staticClass:"member-info"},[_c('span',[_vm._v("Recipient:")]),_vm._v(" "+_vm._s(participant.email)+" ")])})],2)])}),0)]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.hideMembersDialog()}}},[_vm._v("Close")])],1),(item.isSubmitting)?_c('v-progress-linear',{staticClass:"my-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1)],1)],1)])]}}],null,false,2753223923)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }