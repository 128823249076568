export const MESSAGE_TYPES = {
  USER: "USER",
  SHOP: "SHOP",
  GROUP: "GROUP"
};
export const MESSAGE_TYPE_LABELS = {
  [MESSAGE_TYPES.USER]: "USER",
  [MESSAGE_TYPES.SHOP]: "SHOP",
  [MESSAGE_TYPES.GROUP]: "GROUP"
};
export const MESSAGE_USER_TYPES = {
  ALL: "ALL",
  REGULAR: "REGULAR",
  //  VIP: 'VIP',
  INFLUENCER: "INFLUENCER"
};

export const MESSAGE_USER_TYPE_LABELS = {
  [MESSAGE_USER_TYPES.ALL]: "ALL",
  [MESSAGE_USER_TYPES.REGULAR]: "REGULAR",
  // [MESSAGE_USER_TYPES.VIP]: 'VIP',
  [MESSAGE_USER_TYPES.INFLUENCER]: "INFLUENCER"
};

export const ACTION_TYPES = {
  DEEPLINK: "DEEPLINK",
  POPUP: "POPUP",
  POST: "POST",
  PRODUCT: "PRODUCT",
  SHOP: "SHOP",
  SHOPPING_WITH_FRIENDS: "SHOPPING_WITH_FRIENDS",
  SPECIAL_CATEGORY: "SPECIAL_CATEGORY",
  MY_SAVINGS: "MY_SAVINGS",
  NONE: "NONE"
};
export const ACTION_TYPE_LABELS = {
  [ACTION_TYPES.DEEPLINK]: "URL LINK",
  [ACTION_TYPES.POPUP]: "TEXT",
  [ACTION_TYPES.NONE]: "NONE"
  //  [ACTION_TYPES.PRODUCT]: 'PRODUCT',
};

export const MESSAGE_CHANNEL_TYPE = {
  EMAIL: "EMAIL",
  IN_APP: "IN_APP"
};
